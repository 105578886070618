<template>
  <section class="ls-page">
    <div class="ps-section__header ls-page-header-no-bg" style="background-image: url(/img/cover_get_runner.jpg)"></div>
    <div class="ps-section__content ls-page-content mb-5">
      <h3 class="container ls-page-header-subtitle">
        Get what you need ordered and delivered by our trusted runners to any
        recipient in Zimbabwe on your behalf
      </h3>
      <router-view></router-view>
    </div>

    <hr class="row" v-if="auth.isAuthenticated" />
    <div class="row" v-if="auth.isAuthenticated">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4">
        <router-link :to="{
          name: 'filtered_runner_requests',
          params: { status: 'pending' },
        }">
          <div class="card ls-admin-card">
            <div class="card-body">
              <div class="card-text ls-center">
                {{ pendingRequests.length }}
              </div>
            </div>
            <div class="card-footer ls-center">Pending Requests</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4">
        <router-link :to="{
          name: 'filtered_runner_requests',
          params: { status: 'completed' },
        }">
          <div class="card ls-admin-card">
            <div class="card-body">
              <div class="card-text ls-center">
                {{ completedRequests.length }}
              </div>
            </div>
            <div class="card-footer ls-center">Completed Requests</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4">
        <router-link :to="{
          name: 'filtered_runner_requests',
          params: { status: 'in-progress' },
        }">
          <div class="card ls-admin-card">
            <div class="card-body">
              <div class="card-text ls-center">
                {{ inProgressRequests.length }}
              </div>
            </div>
            <div class="card-footer ls-center">Requests In Progress</div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseRunner",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getRunnersDetails();
  },
  data() {
    return {
      runnerCategories: [],
      myRequests: [],
      pendingRequests: [],
      completedRequests: [],
      inProgressRequests: [],
    };
  },
  methods: {
    getRunnersDetails() {
      axios
        .post("runners/user/requests", { status: "pending" })
        .then((response) => {
          //console.log("Get Runner Requests : " + JSON.stringify(response.data.data));
          this.pendingRequests = response.data.data;
          // for (var i = 0; i < this.myRequests.length; i++) {
          //   switch (this.myRequests[i].status) {
          //     case "pending":
          //       this.pendingRequests.push(this.myRequests[i]);
          //       break;
          //     case "completed":
          //       this.completedRequests.push(this.myRequests[i]);
          //       break;
          //     case "in progress":
          //       this.inProgressRequests.push(this.myRequests[i]);
          //       break;
          //   }
          // }
          // this.pendingRequests = response.data.data.length;
        });
      axios
        .post("runners/user/requests", { status: "completed" })
        .then((response) => {
          //console.log("Get Runner Requests : " + JSON.stringify(response.data.data));
          this.completedRequests = response.data.data;
          // this.pendingRequests = response.data.data.length;
        });
      axios
        .post("runners/user/requests", { status: "in progress" })
        .then((response) => {
          //console.log("Get Runner Requests : " + JSON.stringify(response.data.data));
          this.inProgressRequests = response.data.data;
          // this.pendingRequests = response.data.data.length;
        });
      // axios.get("getrunner/runner-categories").then((response) => {
      //   //console.log("Get Runner Cat : " + JSON.stringify(response.data));
      //   this.runnerCategories = response.data;
      // });
    },
  },
};
</script>

<style scoped>
.card-footer {
  background-color: #f9a51e;
}
</style>
