var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ls-page"},[_c('div',{staticClass:"ps-section__header ls-page-header-no-bg",staticStyle:{"background-image":"url(/img/cover_get_runner.jpg)"}}),_c('div',{staticClass:"ps-section__content ls-page-content mb-5"},[_c('h3',{staticClass:"container ls-page-header-subtitle"},[_vm._v(" Get what you need ordered and delivered by our trusted runners to any recipient in Zimbabwe on your behalf ")]),_c('router-view')],1),(_vm.auth.isAuthenticated)?_c('hr',{staticClass:"row"}):_vm._e(),(_vm.auth.isAuthenticated)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4"},[_c('router-link',{attrs:{"to":{
        name: 'filtered_runner_requests',
        params: { status: 'pending' },
      }}},[_c('div',{staticClass:"card ls-admin-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text ls-center"},[_vm._v(" "+_vm._s(_vm.pendingRequests.length)+" ")])]),_c('div',{staticClass:"card-footer ls-center"},[_vm._v("Pending Requests")])])])],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4"},[_c('router-link',{attrs:{"to":{
        name: 'filtered_runner_requests',
        params: { status: 'completed' },
      }}},[_c('div',{staticClass:"card ls-admin-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text ls-center"},[_vm._v(" "+_vm._s(_vm.completedRequests.length)+" ")])]),_c('div',{staticClass:"card-footer ls-center"},[_vm._v("Completed Requests")])])])],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-4 col-4"},[_c('router-link',{attrs:{"to":{
        name: 'filtered_runner_requests',
        params: { status: 'in-progress' },
      }}},[_c('div',{staticClass:"card ls-admin-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text ls-center"},[_vm._v(" "+_vm._s(_vm.inProgressRequests.length)+" ")])]),_c('div',{staticClass:"card-footer ls-center"},[_vm._v("Requests In Progress")])])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }